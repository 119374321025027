// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Alumni Speak',
  nakedPageSlug: 'alumni-speak',
  pageAbstract: 'Content being added at the moment.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1>Alumni Speak</h1>
    <h2 style={{ marginBottom: 0 }}>1. Tanya Sharma</h2>
    <p>
      <i>Class of 2012</i>
    </p>
    <p>
      It is not often that one sits down, early in the morning, to reminisce the
      days gone by. The days, that shaped you to what you are today. As I do
      this today, my mind is flooded with a tonne of thoughts as to what made me
      the woman I have become. I am turning 25 in a few days, and technically
      that’s a long enough time to realize one’s true calling. As Deepa mam
      would say – “Follow your passion, always”.
    </p>
    <p>
      I can proudly say, that I did and still do. There have been times, in my
      life, when I have doubted my capability and turned my head in despair. It
      is then that I consciously sit, and remember the little girl I was at
      school, unafraid and with eyes full of dreams. To a listener, this would
      sound very cliched, however, my batch at CISFG would be able to relate
      this to the core.
    </p>
    <p>
      When I came to Cambridge in class VII, the culture here was in stark
      contrast to the typical convent culture I had seen till now. The hesitant
      little girl in me was encouraged to speak her mind, and was polished in
      way that my natural talent became my strongest strength.  I have a vivid
      memory of the day I went up the stage for the first time in my life, as an
      orator in my own right. It was the applause and the personal encouragement
      from the teachers around, that instilled confidence in me. I cannot fathom
      how different a person I would have been, had this not happened to me. We
      learned and studied for the sheer joy of it. And more than anything, we
      were given the essence of experiential learning.  The moto of - Dream,
      Dare, Do - was instilled to the core of our being.
    </p>
    <p>
      School life at Cambridge was very different. I realize this even more, now
      that I have interacted with hundreds of people, across cities and states.
      We were cushioned (from the grim realities of the world), nurtured in a
      very safe environment, by teachers, who were like our pals. We were
      encouraged to do what fancied us, for it meant unleashing our creativity.
      Never were we snubbed, no matter how childish an idea was, but only guided
      in the right light. These were the teachers, who laid the very foundation
      of the women we were to become. Mentally strong, resilient and
      independent. By independent, I mean the independence of thought, and not
      otherwise.
    </p>
    <p>
      When I look around and observe the school kids these days, I feel blessed
      for the times we were brought up in. I feel really lucky to have studied
      at Cambridge in those days, for the teachers here seemed like a prodigy of
      impeccability. The kind of teachers we had, are a rarity these days. More
      so, as I prepare for a paper on ‘leadership’ in my MBA, I realize the
      leadership role played by Deepa ma’am in our long term development, and
      not just getting good grades.
    </p>
    <p>
      It is hard to state in a few words the criticality of the role played by
      Deepa ma’am in our lives. However, as I grow, I can proudly say that I can
      feel her core beliefs reflecting in me. And that is a testament to the
      impact my dearest teacher had on my life.
    </p>
    <p>
      When I meet my oldest Cambridge friends, I realize, that the school did
      something unique to each one of us. But the one common thread is that tiny
      whisker of pre-teen innocence, which was nurtured here at Cambridge, and
      has come up like a fresh bloom as we grow into women of substance. And
      that is what sets my Cambridge women apart from the rest of the world. I
      am not in touch with a lot of them, however, the few I meet on an off,
      make me proud. About my own self, I would give the entire credit to that
      one decision my mother took years back, to shift me to Cambridge. And I am
      ever grateful.
    </p>
    <hr />
    <h2 style={{ marginBottom: 0 }}>2. Anureet Tiwana</h2>
    <p>
      <i>Class of 2012</i>
    </p>
    <p>
      Cambridge International school has done a commendable job by positively
      impacting the education system. Equity and inclusion are powerful words
      central to ensuring that all students and teachers have the support to
      achieve their potential, these two words resonate with theme of this
      prestigious institution. Cambridge school tends to foresee trends in
      education and in the world at large. It aspire to act with creativity and
      courage in our work. It has transformed industrious students into
      responsible members of the society. Studying at Cambridge have been the
      most formative 2 years of my life. They have set a strong foundation and
      have impacted my thought process which in turn has helped me in making
      some valiant decisions. Cambridge school gave me wings to dream beyond the
      quintessential, to explore the uncharted territory. I swerved away from
      the conventional path of medicine and decided to venture into neuroscience
      with the goal to uncover the fundamental mechanisms that underlie the
      human experience, i.e. who we are. As I begin my journey as a
      neuroscientist in at the University College London (UCL) this September, I
      am extremely thankful to teachers and staff at Cambridge international.
    </p>
    <h2 style={{ marginBottom: 0 }}>3. Sadhika Burman</h2>
    <p>
      <i>Session 2005-2006 to Session 2014-2015, Class of 2015</i>
    </p>
    <p>
      Cambridge and I have a lot in common. We were both growing when we met. I
      was eight when I became a part of my Cambridge family. I went to an all
      girls school for almost half of my life and, NO, I DO NOT regret it or
      think that I missed out on anything. Instead, I believe that these 10
      years were still not enough to grab all the opportunities that were
      offered to me at Cambridge. Dream, Dare, Do wasn’t just a motto or a
      saying that I grew up listening to. It is what I am doing right now in
      this moment.
    </p>
    <p>
      I dreamt of being so confident in my speech and actions, that one day I
      could share my opinion with thousands of people and it would matter. I
      dared to pursue a degree that people advised me not to, since it was “not
      a girls’ place to be” or it was “hard” and finally I am doing what I
      wanted to do. Cambridge has played a huge role in making me the young
      woman I am today.
    </p>
    <p>
      There was never a time where I was discouraged or stopped to do something
      (unless of course it was wrong). I still remember walking up to Deepa mam
      and telling her that I wanted to study War and Investigative Journalism
      and how a lot of the people I discussed it with told me I don’t have the
      health or confidence of being that impactive. She sat there, right in
      front of me, calm like she always is and said, “don’t let what people
      think overpower what you believe in, my bacha”. These words have stuck by
      me for all these years that I’ve been away from home. Cambridge was and
      always will be my comfort place, where I can be myself and let my guard
      down.
    </p>
    <p>
      Being Cambridge Alumni is an honour in itself. I joined Cambridge as a
      baby, I grew up with it. I’m 21 years old today and it is remarkable that
      nothing has changed. The love, trust, respect and friendship that I still
      share with my teachers is so precious that I would go beyond and over my
      capabilities to keep it that way for the remainder of my life. The world
      implied time and again that we belonged to a weaker gender and yet the
      irony is I felt at my strongest when I looked at all the beautiful girls
      building a life they wanted. I am brave, independent and my voice makes a
      difference, does that sound like weak to you?
    </p>
    <hr />
    <p>More stories and content coming soon...</p>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
